import Vue from 'vue';
import ElementUI from 'element-ui';
import VueClipboard from 'vue-clipboard2';

import locale from 'element-ui/lib/locale/lang/ru-RU';
//import locale from 'element-ui/src/locale/lang/ru-RU';

import 'element-ui/lib/theme-chalk/index.css';

const App = () => import("@/App.vue");

import router from './router';

//import timestamp from 'time-stamp';
//import axios from 'axios';
import frontApp from 'sgw_front';



Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });
Vue.use(VueClipboard)


// Базовый путь до api_gw (остальные пути подтягиваются автоматом)

// Токен для сервера (ПРОД-new )
// http
const systemToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzeXN0ZW1UeXBlIjoiZnMiLCJiYXNlVXJsIjoiaHR0cDovLzc4LjI0LjIyMS4yNDo0NzgwIiwiZnNNb2RlVHlwZSI6InByb2QiLCJzeXN0ZW1OYW1lIjoiU3lzR2F0ZXdheSIsInN5c3RlbUlkIjoib1Fud01NIiwiZ2VuZXJhdGVkQnkiOiJUZDdPUGVjTzljYU1Gbm0yZ1hDMCIsImlhdCI6MTY2NzE0MDc4MCwiZXhwIjoxOTM4NjA4NzgwfQ.8MWY4UXdZwoxV9iPEcpQCTcWZhkLtoGvAkkUoNVuuX0';
// (Старый PROD)
// const systemToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzeXN0ZW1UeXBlIjoiZnMiLCJiYXNlVXJsIjoiaHR0cDovLzIxMi4xMDkuMTk0LjI5OjM3ODAiLCJmc01vZGVUeXBlIjoicHJvZCIsInN5c3RlbU5hbWUiOiJTeXNHYXRld2F5Iiwic3lzdGVtSWQiOiJvUW53TU0iLCJnZW5lcmF0ZWRCeSI6IlRkN09QZWNPOWNhTUZubTJnWEMwIiwiaWF0IjoxNjY3MTQwNzgwLCJleHAiOjE3Mzg2MDg3ODB9.kMAIrJkFPtSWS07xwDfZdP3fYwsUMIu5_3lDldfyhdA';


// Токен для сервера (ТЕСТ http://82.146.39.51:4780)
// http
//const systemToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzeXN0ZW1UeXBlIjoiZnMiLCJiYXNlVXJsIjoiaHR0cDovLzgyLjE0Ni4zOS41MTo0NzgwIiwiZnNNb2RlVHlwZSI6InByb2QiLCJzeXN0ZW1OYW1lIjoiU3lzR2F0ZXdheSIsInN5c3RlbUlkIjoib1Fud01NIiwiZ2VuZXJhdGVkQnkiOiJUZDdPUGVjTzljYU1Gbm0yZ1hDMCIsImlhdCI6MTY2NzE0MDc4MCwiZXhwIjoxODk3NTA4NzgwfQ.s-DAXUaE9XWLhLE4qJFDY6C7JOcDVVcWmSvQG4P8kkQ';



const logType = 'dev' // prod - отсутствие логов, uat- только критические логи, dev - все логи

frontApp.start(
    {
        systemToken: systemToken,
        logType: logType, // Опционально, если не задан - то "prod"
        //baseUrl: baseUrl // Опционально, если не задан - берется из токена, если задан, то с параметров
    }
)

Vue.mixin({
    data(){
        return {
            frontApp:frontApp,
        }
    },
   // methods: {
        //log: log,
        // getLogType: function (){
        //     return logType
        // },
        // setLogType: function (newLogType){
        //     localStoragyye.setItem('logType', newLogType);
        // }
    //}
});

router.beforeEach(async (to, from, next) => {

    if (to.meta.requiresAuth && localStorage.getItem("accesstoken") === null) { // если по роуту установлена идентификация
        next({path: '/auth/login'});
    } else {
        next();
    }
});

//Проверка доступности компонентов
router.beforeEach(async (to, from, next) => {
    frontApp.log(`Переход с ${from.path} к ${to.path}`, 'info');
    if (to.meta.requiresAuth){
        //console.log('Проверка действительности токенов 1')
        let authCheck = await frontApp.checkAndRefreshTokens()
        if (authCheck.result === 'error') { // если по роуту установлена идентификация
            //console.log('Проверка действительности токенов 2')
            thisApp.$message({type: 'error', message: thisApp.reason});
            next({path: '/auth/login'});
        } else {
            next();
        }
    } else {
        next();
    }
});


let thisApp = new Vue({
  el: '#app',
  router: router,
  render: h => h(App)
});

